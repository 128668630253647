import React from 'react';
import { Instagram, Facebook } from 'src/assets/icons';

interface SocialMedia {
  name: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  path: string;
}

interface FooterLinks {
  text: string;
  path: string;
}

export const socialMedia: SocialMedia[] = [
  {
    name: 'Instagram',
    icon: Instagram,
    path: 'https://www.instagram.com/mylifebook'
  },
  {
    name: 'Facebook',
    icon: Facebook,
    path: 'https://www.facebook.com/mylifebook'
  }
];

export const footerLinks: FooterLinks[] = [
  {
    text: 'Contact Us',
    path: 'https://support.mylifebook.com/en/'
  },
  {
    text: 'Privacy Policy',
    path: 'https://www.mylifebook.com/privacy'
  },
  {
    text: 'Term of Use',
    path: 'https://www.mylifebook.com/terms'
  },
  {
    text: 'FAQ',
    path: 'https://support.mylifebook.com/'
  }
];

export const noFooterPaths = ['/adminPanel/users', '/adminPanel/scores', '/adminPanel/assessments'];
