import React, { lazy, ReactElement, ReactNode, Suspense } from 'react';

import { AnyPromise, Await, ComponentType } from 'src/types/utils';

type Props<C extends ComponentType> = React.ComponentProps<C>;

export const lazyLoad = <P extends AnyPromise, C extends ComponentType>(
  importFunc: () => P,
  selectorFunc: (module: Await<P>) => C,
  fallback: NonNullable<ReactNode> | null
): React.FunctionComponent<Props<C>> => {
  const lazyFactory = (): Promise<{ default: C }> => importFunc().then((module) => ({ default: selectorFunc(module) }));

  const LazyComponent = lazy(lazyFactory);

  return (props: Props<C>): ReactElement => (
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  );
};
