const PRIMARY = {
  blue: '#203685',
  red: '#C7262C',
  yellow: '#FCCF36',
  orange: {
    main: '#F45929',
    shade_1: '#FCA311',
    shade_2: '#FCCF36'
  }
};

const GREEN = {
  main: '#3F9525',
  shade_1: '#8AC926',
  shade_2: '#AEE304'
};

const GREY = {
  main: '#494949',
  shade_1: '#818181'
};

const COMMON = {
  custom_common: {
    black: '#000000',
    white: {
      main: '#FFFFFF',
      shade_1: '#F5F6FA',
      shade_2: '#F2F2F3'
    }
  },
  custom_primary: { ...PRIMARY, contrastText: '#fff' },
  custom_green: GREEN,
  custom_grey: GREY
};

const BACKGROUND = {
  light: '#FFFFFF',
  default: PRIMARY.blue
};

export const palette = {
  ...COMMON,
  background: { paper: BACKGROUND.light, default: BACKGROUND.default }
};
