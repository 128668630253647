import { Components, Theme } from '@mui/material';

export function createTextField(theme: Theme): Pick<Components, 'MuiTextField'> {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          background: theme.palette.background.paper,

          '&.alignLeft': {
            '& .MuiOutlinedInput-root': {
              '& input[type=number]': {
                textAlign: 'left'
              }
            }
          },

          '&.filterForm': {
            '& .MuiOutlinedInput-root': {
              '& input': {
                fontSize: '12px'
              },
              '& input[type=date]': {
                color: theme.palette.custom_grey.main,
                padding: '10px'
              },
              '& input[type=number]': {
                color: theme.palette.custom_grey.main,
                padding: '10px 10px 10px 24px'
              }
            }
          },

          '& .MuiOutlinedInput-root': {
            '& input': {
              color: theme.palette.custom_primary.blue,
              fontWeight: 700
            },
            '& input[type=number]': {
              textAlign: 'center'
            },
            '& fieldset': {
              border: `1px solid ${theme.palette.custom_grey.shade_1}`,
              borderRadius: theme.shape.borderRadiusSm
            },
            '&:hover fieldset': {
              border: `1px solid ${theme.palette.custom_grey.shade_1}`
            },
            '&.Mui-focused fieldset': {
              border: `2px solid ${theme.palette.custom_primary.blue}`
            }
          }
        }
      }
    }
  };
}
