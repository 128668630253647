import React from 'react';
import { ThemeConfig } from 'src/theme';
import { Layouts } from 'src/layouts';
import { GlobalStylesComponent } from 'src/theme/globalStyles';
import { Provider } from 'react-redux';
import store from 'src/store/store';

function App() {
  return (
    <Provider store={store}>
      <ThemeConfig>
        <GlobalStylesComponent />
        <Layouts />
      </ThemeConfig>
    </Provider>
  );
}

export default App;
