import React, { useCallback, useEffect, useState, useRef } from 'react';
import { CSVLink } from 'react-csv';

import {
  Button,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  useTheme
} from '@mui/material';
import { useAdmin } from 'src/hooks/useAdmin.hook';
import { useSelector } from 'react-redux';
import { adminPanelSelectors } from 'src/store/selectors/adminPanel.selectors';
import { PaginationComponent } from 'src/components/adminPanel/tabs/assessments/paginationComponent';
import { getAdminPanelAssessmentsData, getAdminPanelAssessmentDataById } from 'src/service/assessmentData.service';
import { Data } from 'react-csv/components/CommonPropTypes';
import { useUpdateEffect } from 'src/hooks/useUpdateEffect';

interface CSVFormat {
  results: string | Data;
  answers: string | Data;
  form: string | Data;
}

type CSVLinkType = CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement };

export const AssessmentsTable = () => {
  const theme = useTheme();

  const { updateAssessments } = useAdmin();
  const tabData = useSelector(adminPanelSelectors.getAdminPanelAssessments);
  const filterData = useSelector(adminPanelSelectors.getFilterData);
  const encodedString = useSelector(adminPanelSelectors.getEncodedString);

  const [page, setPage] = useState(0);
  const rowsPerPage = 12;

  const [allData, setAllData] = useState<CSVFormat>({ results: '', answers: '', form: '' });
  const [oneData, setOneData] = useState<CSVFormat>({ results: '', answers: '', form: '' });

  // one assessment CSV refs
  const oneResultsCSVRef = useRef<CSVLinkType>(null);
  const oneAnswersCSVRef = useRef<CSVLinkType>(null);
  const oneFormCSVRef = useRef<CSVLinkType>(null);

  // all assessments CSV refs
  const allResultsCSVRef = useRef<CSVLinkType>(null);
  const allAnswersCSVRef = useRef<CSVLinkType>(null);
  const allFormCSVRef = useRef<CSVLinkType>(null);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tabData.result.length) : 0;

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const onExportAllHandler = useCallback((): void => {
    getAdminPanelAssessmentsData(filterData, encodedString).then((r) =>
      setAllData({
        results: r.results,
        answers: r.answers,
        form: r.form
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  const onExportOneHandler = useCallback(
    (assessmentId: string): void => {
      getAdminPanelAssessmentDataById(assessmentId, filterData, encodedString).then((r) =>
        setOneData({
          results: r.results,
          answers: r.answers,
          form: r.form
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterData]
  );

  useEffect(() => {
    updateAssessments(page * rowsPerPage, rowsPerPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData, page]);

  useUpdateEffect(() => {
    if (oneResultsCSVRef.current && oneData.results.length > 0 && oneData.results !== '') {
      oneResultsCSVRef.current.link.click();
    }
    if (oneAnswersCSVRef.current && oneData.answers.length > 0 && oneData.answers !== '') {
      oneAnswersCSVRef.current.link.click();
    }
    if (oneFormCSVRef.current && oneData.form.length > 0 && oneData.form !== '') {
      oneFormCSVRef.current.link.click();
    }
  }, [oneData]);

  useUpdateEffect(() => {
    if (allResultsCSVRef.current && allData.results.length > 0 && allData.results !== '') {
      allResultsCSVRef.current.link.click();
    }
    if (allAnswersCSVRef.current && allData.answers.length > 0 && allData.answers !== '') {
      allAnswersCSVRef.current.link.click();
    }
    if (allFormCSVRef.current && allData.form.length > 0 && allData.form !== '') {
      allFormCSVRef.current.link.click();
    }
  }, [allData]);

  return (
    <TableContainer component={Paper} sx={{ borderRadius: '8px', pr: 3, pl: 3 }}>
      <Table sx={{ minWidth: 700 }} stickyHeader>
        <TableHead sx={{ borderRadius: '4px' }}>
          <TableRow>
            <StyledTableCell width="25%">ID</StyledTableCell>
            <StyledTableCell width="25%">Name</StyledTableCell>
            <StyledTableCell width="25%">Email</StyledTableCell>
            <StyledTableCell align="right">
              <Button
                variant="contained"
                sx={{
                  padding: '8px 25px',
                  fontSize: '12px',
                  color: theme.palette.custom_common.white,
                  backgroundColor: theme.palette.custom_grey.main,
                  '&:hover': {
                    backgroundColor: theme.palette.custom_grey.main,
                    boxShadow: 'none'
                  }
                }}
                onClick={onExportAllHandler}
              >
                export csv of all
              </Button>
              <CSVLink data={allData?.results} filename={`results.csv`} ref={allResultsCSVRef} target="_blank" />
              <CSVLink data={allData?.answers} filename={`assessment.csv`} ref={allAnswersCSVRef} target="_blank" />
              <CSVLink data={allData?.form} filename={`questionnaire.csv`} ref={allFormCSVRef} target="_blank" />
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ mb: 7 }}>
          {(rowsPerPage > 0
            ? tabData.result.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : tabData.result
          ).map((result) => (
            <StyledTableRow key={result.id}>
              <StyledTableCell component="th" scope="row">
                {result.id}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {result.Name}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {result.Email}
              </StyledTableCell>
              <StyledTableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
                <Button
                  variant="contained"
                  onClick={() => window.open(`/report/${result.id}`, '_blank')}
                  sx={{
                    padding: '8px 25px',
                    fontSize: '12px',
                    mr: 2,
                    color: theme.palette.custom_primary.blue,
                    backgroundColor: theme.palette.custom_common.white.main,
                    boxShadow: 'none',
                    border: `1px solid ${theme.palette.custom_primary.blue}`,
                    '&:hover': {
                      backgroundColor: theme.palette.custom_common.white.main
                    }
                  }}
                >
                  view
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    padding: '8px 25px',
                    fontSize: '12px',
                    border: `1px solid ${theme.palette.custom_primary.blue}`
                  }}
                  onClick={() => onExportOneHandler(result.id)}
                >
                  export csv
                </Button>
                <CSVLink
                  data={oneData?.results}
                  filename={`results_${result.id}.csv`}
                  ref={oneResultsCSVRef}
                  target="_blank"
                />
                <CSVLink
                  data={oneData?.answers}
                  filename={`assessment_${result.id}.csv`}
                  ref={oneAnswersCSVRef}
                  target="_blank"
                />
                <CSVLink
                  data={oneData?.form}
                  filename={`questionnaire_${result.id}.csv`}
                  ref={oneFormCSVRef}
                  target="_blank"
                />
              </StyledTableCell>
            </StyledTableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 72 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              labelRowsPerPage=""
              colSpan={5}
              count={tabData.count}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[]}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page'
                },
                native: true
              }}
              onPageChange={handleChangePage}
              ActionsComponent={PaginationComponent}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    paddingTop: 50,
    backgroundColor: theme.palette.custom_common.white.main,
    color: theme.palette.custom_grey.main
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    paddingTop: 0,
    paddingBottom: 0,
    color: theme.palette.custom_grey.main,
    borderBottom: `1px solid ${theme.palette.custom_grey.main}`
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '72px',
  backgroundColor: theme.palette.custom_common.white.main
}));
