import { Components, Theme } from '@mui/material';

export function createChip(theme: Theme): Pick<Components, 'MuiChip'> {
  return {
    MuiChip: {
      styleOverrides: {
        root: {
          width: '100%',
          maxWidth: '300px',
          height: '40px',
          border: `1px solid ${theme.palette.custom_primary.blue}`,
          borderRadius: '24px',
          fontSize: '16px',
          fontWeight: 'bold',
          textTransform: 'capitalize'
        },
        deletable: {
          height: '25px',
          maxWidth: 'calc(100% - 20px) !important',
          fontSize: '12px',
          fontWeight: 'normal',
          background: theme.palette.custom_common.white.main
        }
      }
    }
  };
}
