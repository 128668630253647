const categoryNameMap = [
  {
    name: 'Health/Fitness',
    avgName: 'healthAndFitnessAvg',
    valName: 'HealthAndFitnessValue',
    fullName: 'Health & Fitness'
  },
  {
    name: 'Intellectual',
    avgName: 'intellectualAvg',
    valName: 'IntellectualValue',
    fullName: 'Intellectual Life'
  },
  {
    name: 'Emotional',
    avgName: 'emotionalAvg',
    valName: 'EmotionalValue',
    fullName: 'Emotional Life'
  },
  {
    name: 'Character',
    avgName: 'characterAvg',
    valName: 'CharacterValue',
    fullName: 'Character'
  },
  {
    name: 'Spiritual',
    avgName: 'spiritualAvg',
    valName: 'SpiritualValue',
    fullName: 'Spiritual Life'
  },
  {
    name: 'Love',
    avgName: 'loveAvg',
    valName: 'LoveValue',
    fullName: 'Love Relationship'
  },
  {
    name: 'Parenting',
    avgName: 'parentingAvg',
    valName: 'ParentingValue',
    fullName: 'Parenting'
  },
  {
    name: 'Social',
    avgName: 'socialAvg',
    valName: 'SocialValue',
    fullName: 'Social Life'
  },
  {
    name: 'Financial',
    avgName: 'financialAvg',
    valName: 'FinancialValue',
    fullName: 'Financial Life'
  },
  {
    name: 'Career',
    avgName: 'careerAvg',
    valName: 'CareerValue',
    fullName: 'Career'
  },
  {
    name: 'Quality of Life',
    avgName: 'qualityOfLifeAvg',
    valName: 'QualityOfLifeValue',
    fullName: 'Quality of Life'
  },
  {
    name: 'Life Vision',
    avgName: 'lifeVisionAvg',
    valName: 'LifeVisionValue',
    fullName: 'Life Vision'
  }
];

export const getCategoryFullName = (name?: string): string => {
  const category = categoryNameMap.find((c) => c.name === name || c.avgName === name || c.valName === name);
  if (category) {
    return category.fullName;
  } else {
    return name || 'category';
  }
};

export const getCategoryShortName = (fullName?: string): string => {
  const category = categoryNameMap.find((c) => c.fullName === fullName);
  if (category) {
    return category.name;
  } else {
    return fullName || 'category';
  }
};

export const getCategoryValueKey = (fullName?: string): string => {
  const category = categoryNameMap.find((c) => c.fullName === fullName);
  if (category) {
    return category.valName;
  } else {
    return fullName || 'category';
  }
};
