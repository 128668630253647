import { QuestionInterface } from 'src/types/question';

export enum ASSESSMENT_STATUS {
  CREATED = 'Created',
  NOT_COMPLETED = 'Not completed',
  COMPLETED = 'Completed'
}

export enum ASSESSMENT_TAG {
  ALL = 'All',
  SPECIAL = 'Special',
  IN_RELATIONSHIP = 'In relationship',
  SINGLE = 'Single',
  SINGLE_BUT_NOT_LOOKING = 'Single but not looking',
  PARENT = 'Parent',
  NOT_PARENT = 'Not parent',
  FATHER_LIVING = 'Father is living',
  MOTHER_LIVING = 'Mother is living',
  SIBLINGS_LIVING = 'Have a siblings and any are living'
}

export interface AssessmentStateInterface {
  assessment: AssessmentInterface;
}

export interface AssessmentInterface {
  id: string;
  status: ASSESSMENT_STATUS;
  questions: QuestionInterface[];
  answers: AnswerInterface[];
  name: string;
  tags: ASSESSMENT_TAG[];
  results?: ResultInterface;
  categoryOfFocus?: string;
  completedAt?: Date;
}

export interface AnswerInterface {
  id: string;
  questionId: string;
  value: number;
  secondValue?: number;
  unitOfSecondValue?: string;
}

export interface ResultInterface {
  healthAndFitnessValue: number;
  intellectualValue: number;
  emotionalValue: number;
  characterValue: number;
  spiritualValue: number;
  loveValue: number;
  parentingValue: number;
  socialValue: number;
  financialValue: number;
  careerValue: number;
  qualityOfLifeValue: number;
  lifeVisionValue: number;
}
