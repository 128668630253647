import { styled } from '@mui/material';

import { Router } from 'src/routes';
import { Footer } from 'src/layouts/footer';
import { useLocation } from 'react-router-dom';
import { noFooterPaths } from 'src/layouts/constants';

export const Layouts = () => {
  const { pathname } = useLocation();

  return (
    <StyledContainer id="PageContainer">
      <Router />
      {!noFooterPaths.includes(pathname) && <Footer />}
    </StyledContainer>
  );
};

const StyledContainer = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  overflowY: 'hidden',
  overflowX: 'hidden',
  display: 'flex',
  flexDirection: 'column',

  // For almost all browsers
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  // For mozilla
  scrollbarWidth: 'none',

  [theme.breakpoints.down('sm')]: {
    minHeight: '100%',
    height: 'auto'
  }
}));
