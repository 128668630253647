import { RootState } from 'src/store/types';
import { AdminPanelSliceState } from 'src/store/reducers/adminPanel/types';
import { createSelector } from 'reselect';

const getAdminPanelState = (state: RootState): AdminPanelSliceState => state.adminPanelState;

const getIsAdmin = createSelector([getAdminPanelState], (adminPanelState: AdminPanelSliceState) => {
  return adminPanelState.isAuthenticated;
});

const getEncodedString = createSelector([getAdminPanelState], (adminPanelState: AdminPanelSliceState) => {
  return adminPanelState.adminEncodedString;
});

const getFilterData = createSelector([getAdminPanelState], (adminPanelState: AdminPanelSliceState) => {
  return adminPanelState.adminPanelFilters;
});

const getAdminPanelUsersData = createSelector([getAdminPanelState], (adminPanelState: AdminPanelSliceState) => {
  return adminPanelState.adminPanelUsers;
});

const getAdminPanelScoresData = createSelector([getAdminPanelState], (adminPanelState: AdminPanelSliceState) => {
  return adminPanelState.adminPanelScores;
});

const getAdminPanelAssessments = createSelector([getAdminPanelState], (adminPanelState: AdminPanelSliceState) => {
  return adminPanelState.adminPanelAssessments;
});

const getAssessmentsCount = createSelector([getAdminPanelState], (adminPanelState: AdminPanelSliceState) => {
  return adminPanelState.adminPanelAssessments.count;
});

const getAllAssessmentsCount = createSelector([getAdminPanelState], (adminPanelState: AdminPanelSliceState) => {
  return adminPanelState.adminPanelUsers.general.allAssessments;
});

export const adminPanelSelectors = {
  getIsAdmin,
  getAdminPanelUsersData,
  getAdminPanelScoresData,
  getFilterData,
  getAdminPanelAssessments,
  getEncodedString,
  getAssessmentsCount,
  getAllAssessmentsCount
};
