import { useDispatch } from 'react-redux';
import { AppThunkDispatch } from 'src/store/types';
import { useCallback } from 'react';
import { loginAction } from 'src/store/asyncActions/login.action';
import { updateAdminPanelUsersAction } from 'src/store/asyncActions/updateAdminPanelUsers.action';
import { updateAdminPanelScoresAction } from 'src/store/asyncActions/updateAdminPanelScores.action';
import { updateAdminPanelAssessmentsAction } from 'src/store/asyncActions/updateAdminPanelAssessments.action';

type UseAdminReturn = {
  login: (params: { login: string; password: string }) => Promise<void>;
  updateUsers: () => Promise<void>;
  updateScores: () => Promise<void>;
  updateAssessments: (offset: number, limit: number) => Promise<void>;
};

export const useAdmin = (): UseAdminReturn => {
  const dispatch = useDispatch<AppThunkDispatch>();

  const login = useCallback(
    (data: { login: string; password: string }): Promise<void> => {
      return dispatch(loginAction(data));
    },
    [dispatch]
  );

  const updateUsers = useCallback((): Promise<void> => {
    return dispatch(updateAdminPanelUsersAction());
  }, [dispatch]);

  const updateScores = useCallback((): Promise<void> => {
    return dispatch(updateAdminPanelScoresAction());
  }, [dispatch]);

  const updateAssessments = useCallback(
    (offset: number, limit: number): Promise<void> => {
      return dispatch(updateAdminPanelAssessmentsAction(offset, limit));
    },
    [dispatch]
  );

  return { login, updateUsers, updateScores, updateAssessments };
};
