import { alpha, Components, Theme } from '@mui/material';

export function createIconButton(theme: Theme): Pick<Components, 'MuiIconButton'> {
  return {
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius,
          border: 'none'
        },
        sizeSmall: {
          width: '20px',
          height: '20px'
        },
        sizeMedium: {
          width: '40px',
          height: '40px'
        },
        colorPrimary: {
          backgroundColor: theme.palette.custom_primary.blue,
          color: theme.palette.custom_common.white.main
        },
        colorSecondary: {
          backgroundColor: theme.palette.custom_common.white.main,
          color: theme.palette.custom_grey.shade_1,
          boxShadow: `2px 6px 16px ${alpha(theme.palette.custom_grey.shade_1, 0.15)}`,
          '&:hover': {
            border: `1px solid ${theme.palette.custom_grey.shade_1}`
          }
        },
        disabled: {
          backgroundColor: theme.palette.custom_common.white.main,
          color: theme.palette.custom_grey.shade_1,
          boxShadow: 'none'
        }
      }
    }
  };
}
