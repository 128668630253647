import { Buffer } from 'buffer';

import { AppThunkAction } from 'src/store/types';
import { login } from 'src/service/assessmentData.service';
import { setAdminEncodedString, setAuth } from 'src/store/reducers/adminPanel/adminPanelReducer';
import { batch } from 'react-redux';
import { setState } from 'src/store/reducers/state/stateReducer';
import { StateEnum } from 'src/store/reducers/state/types';

export const loginAction = (data: { login: string; password: string }): AppThunkAction => {
  return async function thunk(dispatch): Promise<void> {
    const encodedString = Buffer.from(`${data.login}@${data.password}`).toString('base64');

    try {
      dispatch(setState(StateEnum.PENDING));
      await login(encodedString);

      localStorage.setItem('loginTime', new Date().getTime().toString());
      localStorage.setItem('token', encodedString);

      batch(() => {
        dispatch(setAuth(true));
        dispatch(setAdminEncodedString(encodedString));
      });
    } catch (err) {
      console.log(err);
      dispatch(setAuth(false));
    }
  };
};
