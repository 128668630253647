import { GlobalStyles, useTheme } from '@mui/material';

export const GlobalStylesComponent = () => {
  const theme = useTheme();

  return (
    <GlobalStyles
      styles={{
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box'
        },
        html: {
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch'
        },
        body: {
          width: '100%',
          height: '100%',
          background: theme.palette.background.default,
          // To prevent fonts from pixelation and optimize text legibility on the device screens
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
          textRendering: 'optimizeLegibility'
        },
        '#root': {
          width: '100%',
          height: '100%',
          background: theme.palette.background.default
        },
        '#finalForm': {
          // For almost all browsers
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          // For mozilla
          scrollbarWidth: 'none'
        },
        // override srollbar styles
        '::-webkit-scrollbar': {
          width: 8
        },
        '::-webkit-scrollbar-track': {
          background: theme.palette.custom_common.white.shade_1
        },

        '::-webkit-scrollbar-thumb': {
          minHeight: 50,
          background: theme.palette.custom_primary.blue,
          borderRadius: theme.shape.borderRadiusXxs,
          '&:hover': {
            background: theme.palette.custom_primary.blue
          }
        }
      }}
    />
  );
};
