import { AppThunkAction } from 'src/store/types';
import { getAdminPanelAssessments, getAdminPanelUsersData } from 'src/service/assessmentData.service';
import { setAdminPanelAssessments, setAdminPanelUsers } from 'src/store/reducers/adminPanel/adminPanelReducer';
import { batch } from 'react-redux';
import { setState } from 'src/store/reducers/state/stateReducer';
import { StateEnum } from 'src/store/reducers/state/types';

export const updateAdminPanelUsersAction = (): AppThunkAction => {
  return async function thunk(dispatch, getState): Promise<void> {
    const state = getState();
    const encodedString = state.adminPanelState.adminEncodedString;
    const params = state.adminPanelState.adminPanelFilters;
    try {
      dispatch(setState(StateEnum.PENDING));
      const adminPanelUsersData = await getAdminPanelUsersData(params, encodedString);
      const adminPanelScoresData = await getAdminPanelAssessments({ ...params, offset: 0, limit: 12 }, encodedString);

      batch(() => {
        dispatch(setAdminPanelUsers(adminPanelUsersData));
        dispatch(setAdminPanelAssessments(adminPanelScoresData));
        dispatch(setState(StateEnum.COMPLETE));
      });
    } catch (err) {
      console.log(err);
    }
  };
};
