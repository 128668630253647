import merge from 'lodash/merge';

import { Components, Theme } from '@mui/material';

import { createButton } from 'src/theme/overrides/button';
import { createIconButton } from 'src/theme/overrides/iconButton';
import { createTextField } from 'src/theme/overrides/textField';
import { createRadioButton } from 'src/theme/overrides/radioButton';
import { createSelect } from 'src/theme/overrides/select';
import { createProgressBar } from 'src/theme/overrides/progressBar';
import { createAutocomplete } from 'src/theme/overrides/autocomplete';
import { createPopover } from 'src/theme/overrides/popover';
import { createToggleButton } from 'src/theme/overrides/toggleButton';
import { createChip } from 'src/theme/overrides/chip';
import { createTableFooter } from 'src/theme/overrides/tableFooter';
import { createBaseline } from 'src/theme/overrides/baseline';

export function createComponentsOverrides(theme: Theme): Components {
  return merge(
    createButton(theme),
    createIconButton(theme),
    createTextField(theme),
    createRadioButton(theme),
    createSelect(theme),
    createProgressBar(theme),
    createAutocomplete(theme),
    createPopover(theme),
    createToggleButton(theme),
    createChip(theme),
    createTableFooter(theme),
    createBaseline()
  );
}
