import { Components, Theme } from '@mui/material';

export function createToggleButton(theme: Theme): Pick<Components, 'MuiToggleButton'> {
  return {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'lowercase',
          border: 'none',
          backgroundColor: theme.palette.custom_common.white.shade_1,
          color: theme.palette.custom_primary.blue,
          borderRadius: 0,
          borderBottomLeftRadius: theme.shape.borderRadiusMd,
          borderBottomRightRadius: theme.shape.borderRadiusMd,

          '&.Mui-selected': {
            backgroundColor: theme.palette.custom_primary.blue,
            color: theme.palette.custom_common.white.shade_1
          },

          '&:hover': {
            backgroundColor: theme.palette.custom_common.white.shade_1,
            color: theme.palette.custom_primary.blue
          }
        }
      }
    }
  };
}
