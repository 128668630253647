import { combineReducers } from 'redux';
import assessmentReducer from 'src/store/reducers/assessment/assessmentReducer';
import categoriesReducer from 'src/store/reducers/categories/categoriesReducer';
import stateReducer from 'src/store/reducers/state/stateReducer';
import adminPanelReducer from 'src/store/reducers/adminPanel/adminPanelReducer';

const rootReducer = combineReducers({
  assessmentState: assessmentReducer,
  categoriesState: categoriesReducer,
  appState: stateReducer,
  adminPanelState: adminPanelReducer
});

export default rootReducer;
