import axios, { AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';

export const getAxiosInstance = (): AxiosInstance => {
  const client = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  });

  axiosRetry(client, {
    retries: 2,
    retryDelay: () => 2000,
    shouldResetTimeout: true,
    retryCondition: (error) => {
      return error.config.method === 'get';
    }
  });

  return client;
};
