import { Stack, SvgIcon, Typography, useTheme } from '@mui/material';
import { GrowthIcon } from 'src/assets/icons';

interface SmallCardProps {
  variant: 'primary' | 'secondary';
  title: string | number;
  label: string;
  percentageValue?: number;
}

export const SmallCard = ({ variant, title, label, percentageValue }: SmallCardProps) => {
  const theme = useTheme();

  const getColor = (isPrimary: boolean) => {
    return isPrimary ? theme.palette.custom_primary.blue : theme.palette.custom_common.white.main;
  };

  return (
    <Stack
      alignItems="baseline"
      sx={{
        p: 3,
        mr: 3,
        width: 250,
        borderRadius: '8px',
        background: getColor(variant === 'primary')
      }}
    >
      <Stack width="100%" direction="row" alignItems="baseline" justifyContent="space-between">
        <Typography variant="h4" color={getColor(variant !== 'primary')}>
          {title}
        </Typography>
        {!!percentageValue && (
          <Stack direction="row" alignItems="baseline" sx={{ mr: 5 }}>
            <Typography variant="p" color={getColor(variant !== 'primary')}>
              {`${percentageValue > 0 ? '+' : ''}${percentageValue.toFixed(2)}%`}
            </Typography>
            <SvgIcon viewBox="0 -5 18 19" stroke={theme.palette.custom_common.white.main} sx={{ ml: '4px' }}>
              <GrowthIcon />
            </SvgIcon>
          </Stack>
        )}
      </Stack>
      <Typography variant="label" color={getColor(variant !== 'primary')}>
        {label}
      </Typography>
    </Stack>
  );
};
