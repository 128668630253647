import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createInitialCategoriesState } from 'src/store/reducers/categories/createInitialCategoriesState';
import { CategoriesSliceState, CategoryInterface } from 'src/store/reducers/categories/types';

const categoriesSlice = createSlice({
  name: 'categories',
  initialState: createInitialCategoriesState(),
  reducers: {
    setCategories(state: CategoriesSliceState, action: PayloadAction<CategoryInterface[]>): void {
      state.categories = action.payload;
    },
    setCategoriesContent(state: CategoriesSliceState, action: PayloadAction<Partial<CategoryInterface>[]>): void {
      action.payload.forEach((content) => {
        Object.keys(content).forEach((key) => {
          if (key === 'content' || 'contentOfFocus' || 'contentOfStrength') {
            const category = state.categories.find((c) => c.name === content.name);
            if (category) {
              const categoryIndex = state.categories.indexOf(category);
              state.categories[categoryIndex][key as keyof CategoryInterface] =
                content[key as keyof CategoryInterface] || '';
            } else {
              if (content.name) {
                state.categories = [
                  ...state.categories,
                  { id: content.name, name: content.name, content: content.content }
                ];
              }
            }
          }
        });
      });
    }
  }
});

const { actions, reducer: categoriesReducer } = categoriesSlice;

export { createInitialCategoriesState };

export const { setCategories, setCategoriesContent } = actions;
export default categoriesReducer;
