import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createInitialState } from 'src/store/reducers/state/createInitialState';
import { StateEnum, StateSliceState } from 'src/store/reducers/state/types';

const stateSlice = createSlice({
  name: 'state',
  initialState: createInitialState(),
  reducers: {
    setState(state: StateSliceState, action: PayloadAction<StateEnum>): void {
      state.state = action.payload;
    }
  }
});

const { actions, reducer: stateReducer } = stateSlice;

export { createInitialState };

export const { setState } = actions;

export default stateReducer;
