import React from 'react';

import {
  Box,
  Button,
  CardMedia,
  IconButton,
  Stack,
  styled,
  SvgIcon,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';

import colorPallet from 'src/assets/images/colorPallet.png';
import { HomeIcon, LifebookLogo } from 'src/assets/icons';
import { footerLinks, socialMedia } from 'src/layouts/constants';

export const Footer = () => {
  const theme = useTheme();
  const isMediumSize = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const DesktopFooter = () => (
    <StyledBox>
      <Stack alignItems="center" direction="row">
        <SvgIcon
          viewBox="0 0 183 24"
          sx={{ width: 183, height: 24, fill: theme.palette.custom_common.black, transform: 'scale(0.5)' }}
        >
          <LifebookLogo />
        </SvgIcon>
        <Typography variant="p" color={theme.palette.custom_grey.main} sx={{ fontWeight: 300 }}>
          Copyright © Lifebook. All rights reserved
        </Typography>
      </Stack>
      <Stack alignItems="center" direction="row">
        {socialMedia.map((media) => (
          <IconButton key={media.name} onClick={() => window.open(media.path)} sx={{ backgroundColor: 'transparent' }}>
            <SvgIcon viewBox="0 0 32 32" sx={{ width: 32, height: 32, fill: theme.palette.custom_primary.blue }}>
              <media.icon />
            </SvgIcon>
          </IconButton>
        ))}
      </Stack>
      <Stack alignItems="center" direction="row">
        <>
          {footerLinks.map((link) => (
            <Typography
              key={link.text}
              variant="p"
              color={theme.palette.custom_grey.main}
              onClick={() => window.open(link.path)}
              sx={{ mr: 3, cursor: 'pointer', fontWeight: 300 }}
            >
              {link.text}
            </Typography>
          ))}
          <Button
            variant="contained"
            size="small"
            color="primary"
            startIcon={
              <SvgIcon viewBox="0 2 22 22" sx={{ width: 16, height: 16, fill: theme.palette.custom_common.black }}>
                <HomeIcon />
              </SvgIcon>
            }
            onClick={() => window.open('https://www.mylifebook.com')}
            sx={{ borderRadius: `${theme.shape.borderRadiusXs}px`, ml: 1 }}
          >
            Home
          </Button>
        </>
      </Stack>
    </StyledBox>
  );

  const MobileFooter = () => (
    <StyledBox sx={{ pt: 1, pr: 3, pb: 4, pl: 3 }}>
      <Stack alignItems="center" justifyContent="space-between" direction="row">
        <SvgIcon
          viewBox="0 0 183 24"
          sx={{
            width: 183,
            height: 24,
            position: 'relative',
            left: -45,
            fill: theme.palette.custom_common.black,
            transform: 'scale(0.5)'
          }}
        >
          <LifebookLogo />
        </SvgIcon>
        <Stack direction="row">
          {socialMedia.map((media) => (
            <IconButton
              key={media.name}
              onClick={() => window.open(media.path)}
              sx={{ backgroundColor: 'transparent' }}
            >
              <SvgIcon viewBox="0 0 32 32" sx={{ width: 32, height: 32, fill: theme.palette.custom_primary.blue }}>
                <media.icon />
              </SvgIcon>
            </IconButton>
          ))}
        </Stack>
      </Stack>
      <Stack alignItems="center" justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
        {footerLinks.map((link) => (
          <Typography
            key={link.text}
            variant="p"
            color={theme.palette.custom_grey.main}
            onClick={() => window.open(link.path)}
            sx={{ cursor: 'pointer', fontWeight: 600 }}
          >
            {link.text}
          </Typography>
        ))}
      </Stack>
      <Stack alignItems="center" justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
        <Typography variant="p" color={theme.palette.custom_grey.main} sx={{ fontWeight: 300 }}>
          Copyright © Lifebook. All rights reserved
        </Typography>
        <Button
          variant="contained"
          size="small"
          color="primary"
          startIcon={
            <SvgIcon viewBox="0 2 22 22" sx={{ width: 16, height: 16, fill: theme.palette.custom_common.black }}>
              <HomeIcon />
            </SvgIcon>
          }
          onClick={() => window.open('https://www.mylifebook.com')}
          sx={{ borderRadius: `${theme.shape.borderRadiusXs}px`, ml: 1 }}
        >
          Home
        </Button>
      </Stack>
    </StyledBox>
  );

  return (
    <Box position="relative" sx={{ backgroundColor: theme.palette.custom_common.white.main }}>
      <CardMedia component="img" image={colorPallet} sx={{ width: '100%', height: '8px' }} />
      {isMediumSize ? <MobileFooter /> : <DesktopFooter />}
    </Box>
  );
};

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: 8,
  zIndex: 100,

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column'
  }
}));
