import { Components, Theme } from '@mui/material';

export function createRadioButton(theme: Theme): Pick<Components, 'MuiRadio'> {
  return {
    MuiRadio: {
      styleOverrides: {
        root: {
          color: theme.palette.custom_primary.blue,
          backgroundColor: theme.palette.custom_common.white.shade_1,

          '&.Mui-checked': {
            color: theme.palette.custom_primary.blue
          }
        }
      }
    }
  };
}
