import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createInitialAssessmentState } from 'src/store/reducers/assessment/createInitialAssessmentState';
import { AnswerInterface, AssessmentStateInterface, ResultInterface } from 'src/store/reducers/assessment/types';
import { AssessmentInterface } from 'src/types/assessment';

const assessmentSlice = createSlice({
  name: 'assessment',
  initialState: createInitialAssessmentState(),
  reducers: {
    setAssessment(state: AssessmentStateInterface, action: PayloadAction<AssessmentInterface>): void {
      state.assessment = action.payload;
    },
    setAssessmentId(state: AssessmentStateInterface, action: PayloadAction<string>): void {
      state.assessment.id = action.payload;
    },
    setAnswers(state: AssessmentStateInterface, action: PayloadAction<AnswerInterface[]>): void {
      state.assessment.answers = action.payload;
    },
    setAnswerValue(
      state: AssessmentStateInterface,
      action: PayloadAction<{ answer: AnswerInterface; indexOfAnswer: number }>
    ): void {
      state.assessment.answers[action.payload.indexOfAnswer] = action.payload.answer;
    },
    setResults(state: AssessmentStateInterface, action: PayloadAction<ResultInterface>): void {
      state.assessment.results = action.payload;
    }
  }
});

const { actions, reducer: assessmentReducer } = assessmentSlice;

export { createInitialAssessmentState };

export const { setAssessment, setAssessmentId, setAnswers, setAnswerValue, setResults } = actions;
export default assessmentReducer;
