import { AdminPanelSliceState } from 'src/store/reducers/adminPanel/types';
import { isLessThan24HourAgo } from 'src/utils/isLessThan24HourAgo';

export const createInitialAdminPanelState = (): AdminPanelSliceState => ({
  isAuthenticated: isLessThan24HourAgo(),
  adminPanelFilters: {},
  adminEncodedString: localStorage.getItem('token') || '',
  adminPanelUsers: {
    general: {
      allAssessments: 0,
      assessments: 0,
      countries: 0,
      uniqueUsers: 0
    },
    questions: [],
    dropOffRate: [],
    userProfile: {
      age: {},
      gender: {},
      levelOfEducation: {},
      parentingStatus: {},
      relationshipStatus: {},
      religion: {},
      country: {}
    }
  },
  adminPanelScores: {
    general: {
      lifeQuotientAvg: '0',
      worldLifeQuotientAvg: '0'
    },
    categoryOfFocus: [],
    categoryBreakdown: {},
    lifeQuotientComparison: {},
    averageScoresPerCategory: {}
  },
  adminPanelAssessments: {
    result: [],
    count: 0,
    pageNumber: -1
  }
});
