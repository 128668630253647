import { ASSESSMENT_STATUS, AssessmentStateInterface } from 'src/store/reducers/assessment/types';

export const createInitialAssessmentState = (): AssessmentStateInterface => ({
  assessment: {
    id: '',
    name: '',
    status: ASSESSMENT_STATUS.CREATED,
    questions: [],
    answers: [],
    tags: []
  }
});
