import { Components, Theme } from '@mui/material';

export function createPopover(theme: Theme): Pick<Components, 'MuiPopover'> {
  return {
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: theme.shape.borderRadiusSm
        }
      }
    }
  };
}
