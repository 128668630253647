import { Theme } from '@mui/material';

export const getChartColors = (theme: Theme) => {
  return [
    theme.palette.custom_primary.blue,
    theme.palette.custom_green.main,
    theme.palette.custom_green.shade_1,
    theme.palette.custom_primary.orange.shade_2,
    theme.palette.custom_primary.orange.shade_1,
    theme.palette.custom_primary.orange.main,
    theme.palette.custom_primary.red
  ];
};
