import { Components, Theme } from '@mui/material';

export function createAutocomplete(theme: Theme): Pick<Components, 'MuiAutocomplete'> {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '&.filterForm': {
            '& .MuiOutlinedInput-root': {
              padding: '0 0 0 16px',
              '& input': {
                color: theme.palette.custom_grey.main,
                padding: '8px',
                fontSize: '12px'
              },
              '.MuiAutocomplete-endAdornment': {
                top: '-4px'
              }
            }
          }
        },
        popper: {
          '.MuiAutocomplete-paper': {
            padding: '10px 0',
            borderRadius: theme.shape.borderRadiusSm
          }
        },
        listbox: {
          maxHeight: '160px'
        }
      }
    }
  };
}
