import { Autocomplete, Stack, SvgIcon, TextField, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { adminPanelSelectors } from 'src/store/selectors/adminPanel.selectors';
import { SmallCard } from 'src/components/adminPanel/tabs/users/card';
import React, { useEffect, useState } from 'react';
import { useAdmin } from 'src/hooks/useAdmin.hook';
import { AdminPanelBarChart } from 'src/components/adminPanel/charts/barChart';
import { ArrowDown } from 'src/assets/icons';
import { getCategoryFullName, getCategoryValueKey } from 'src/utils/getCategoryFullName';

export const AdminScores = () => {
  const theme = useTheme();
  const { updateScores } = useAdmin();
  const tabData = useSelector(adminPanelSelectors.getAdminPanelScoresData);
  const filterData = useSelector(adminPanelSelectors.getFilterData);

  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const calcPercentage = (value: number) => {
    const sum = tabData.categoryOfFocus.reduce((partialSum, el) => partialSum + parseInt(el.count), 0);
    return parseFloat(((value / sum) * 100).toFixed(0));
  };

  useEffect(() => {
    updateScores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  return (
    <Stack
      width="100%"
      height="100%"
      alignItems="baseline"
      sx={{ backgroundColor: theme.palette.custom_common.white.shade_1 }}
    >
      <Stack direction="row" sx={{ p: 3 }}>
        <SmallCard
          variant="primary"
          title={parseInt(tabData.general.lifeQuotientAvg).toFixed(0)}
          label="Average Life Quotient"
          percentageValue={
            (parseInt(tabData.general.lifeQuotientAvg) / parseInt(tabData.general.worldLifeQuotientAvg)) * 100 - 100
          }
        />
        <SmallCard
          variant="primary"
          title={parseInt(tabData.general.worldLifeQuotientAvg).toFixed(0)}
          label="World Average"
        />
      </Stack>
      <Stack direction="row" width="100%">
        <Stack
          width="50%"
          alignItems="baseline"
          sx={{ p: 2, mr: 3, ml: 3, borderRadius: '8px', backgroundColor: theme.palette.custom_common.white.main }}
        >
          <Typography variant="h5" color={theme.palette.custom_primary.blue} sx={{ fontWeight: 'bold' }}>
            Average Scores per Category
          </Typography>
          <AdminPanelBarChart
            direction="horizontal"
            shouldFormat={false}
            labels={Object.keys(tabData.averageScoresPerCategory)}
            data={Object.values(tabData.averageScoresPerCategory).map((el) => Math.round(parseFloat(el)))}
          />
        </Stack>
        <Stack
          width="50%"
          alignItems="baseline"
          sx={{ p: 2, mr: 3, ml: 3, borderRadius: '8px', backgroundColor: theme.palette.custom_common.white.main }}
        >
          <Typography variant="h5" color={theme.palette.custom_primary.blue} sx={{ fontWeight: 'bold' }}>
            Category of Focus
          </Typography>
          <AdminPanelBarChart
            direction="horizontal"
            shouldFormat={true}
            labels={tabData.categoryOfFocus.map((el) => el.CategoryOfFocus)}
            data={tabData.categoryOfFocus.map((el) => calcPercentage(parseInt(el.count)))}
          />
        </Stack>
      </Stack>
      <Stack width="100%" sx={{ p: 3, backgroundColor: theme.palette.custom_common.white.shade_1 }}>
        <Stack
          sx={{
            p: 2,
            width: 'stretch',
            borderRadius: '8px',
            backgroundColor: theme.palette.custom_common.white.main
          }}
        >
          <Stack direction="row" justifyContent="space-between" width="100%">
            <Stack>
              <Typography variant="h5" color={theme.palette.custom_primary.blue} sx={{ fontWeight: 'bold' }}>
                Category Breakdown:{' '}
                <span style={{ color: theme.palette.custom_primary.orange.shade_1 }}>{selectedCategory}</span>
              </Typography>
              <Typography variant="p" color={theme.palette.custom_grey.shade_1} sx={{ mt: 1 }}>
                (Users' range of scores for the selected category)
              </Typography>
            </Stack>
            <Stack sx={{ minWidth: '230px' }}>
              <Autocomplete
                popupIcon={
                  <SvgIcon viewBox="0 0 14 8" sx={{ width: 14, height: 8, fill: theme.palette.custom_primary.blue }}>
                    <ArrowDown />
                  </SvgIcon>
                }
                renderInput={(params) => <TextField {...params} placeholder="Choose Category" sx={{ width: '95%' }} />}
                options={Object.keys(tabData.categoryBreakdown).map((key) => getCategoryFullName(key))}
                onChange={(e, value) => setSelectedCategory(value)}
                sx={{
                  p: 0,
                  '& .MuiOutlinedInput-root': {
                    '.MuiAutocomplete-endAdornment': {
                      top: 8
                    }
                  }
                }}
              />
            </Stack>
          </Stack>
          <Stack>
            {selectedCategory ? (
              <AdminPanelBarChart
                direction="vertical"
                shouldFormat={false}
                labels={Object.keys(tabData.categoryBreakdown[getCategoryValueKey(selectedCategory)])}
                // data={tabData.categoryOfFocus.map((el) => calcPercentage(parseInt(el.count)))}
                data={Object.values(tabData.categoryBreakdown[getCategoryValueKey(selectedCategory)])}
              />
            ) : (
              <Stack alignItems="center" justifyContent="center" sx={{ p: 10 }}>
                <Typography variant="h3" color={theme.palette.custom_primary.blue} sx={{ opacity: 0.3 }}>
                  Select category
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
        <Stack
          sx={{
            p: 2,
            mt: 3,
            width: 'stretch',
            borderRadius: '8px',
            backgroundColor: theme.palette.custom_common.white.main
          }}
        >
          <Typography variant="h5" color={theme.palette.custom_primary.blue} sx={{ fontWeight: 'bold' }}>
            Life Quotient Comparison Graph Breakdown
          </Typography>
          <AdminPanelBarChart
            direction="vertical"
            shouldFormat={true}
            labels={Object.keys(tabData.lifeQuotientComparison)}
            data={Object.values(tabData.lifeQuotientComparison).map((el) => parseInt(el))}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
