import { Stack, useTheme } from '@mui/material';
import { AssessmentsTable } from 'src/components/adminPanel/tabs/assessments/table';

export const AdminAssessments = () => {
  const theme = useTheme();

  return (
    <Stack
      width="100%"
      height="100%"
      alignItems="baseline"
      sx={{ backgroundColor: theme.palette.custom_common.white.shade_1, p: 3 }}
    >
      <AssessmentsTable />
    </Stack>
  );
};
