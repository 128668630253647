export const PATHS = {
  root: '/',
  profileForm: '/profileForm',
  assessment: '/assessment/:id',
  assessmentQuestions: '/assessment/:id/question/:questionId',
  assessmentFinalForm: '/finalForm/:id',
  thankYouPage: '/getReport/:id',
  reportPage: '/report/:id',
  adminPanel: '/adminPanel',
  page404: '/404'
};
