import {
  Stack,
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
  SvgIcon,
  useTheme
} from '@mui/material';
import { SortIcon } from 'src/assets/icons';
import { useEffect, useState } from 'react';
import { DropOfRateQuestion } from 'src/store/reducers/adminPanel/types';
import { useSelector } from 'react-redux';
import { adminPanelSelectors } from 'src/store/selectors/adminPanel.selectors';

interface UserTableProps {
  data: {
    questionNumber: number;
    dropOffRate: number;
  }[];
  questions: DropOfRateQuestion[];
}

export const UsersTable = ({ data, questions }: UserTableProps) => {
  const theme = useTheme();

  const assessmentsCount = useSelector(adminPanelSelectors.getAllAssessmentsCount);

  const [tableRevert, setTableRevert] = useState(false);

  const [sortedQuestions, setSortedQuestion] = useState<DropOfRateQuestion[]>([]);

  const calcPercentage = (qOrder: number) => {
    const questionIndex = data.findIndex((el) => el.questionNumber === qOrder);
    if (questionIndex > -1) {
      if (qOrder === 1) {
        const dif =
          assessmentsCount - data.slice(questionIndex, data.length).reduce((acc, cur) => acc + cur.dropOffRate, 0);
        const percentage = (dif / assessmentsCount) * 100;
        return percentage.toFixed(2);
      } else {
        const prevSum = data.slice(questionIndex - 1, data.length).reduce((acc, cur) => acc + cur.dropOffRate, 0);
        const percentage = (data[questionIndex].dropOffRate / prevSum) * 100;

        return qOrder === 106 ? '100' : percentage.toFixed(2);
      }
    } else {
      return '0';
    }
  };

  const sortQuestions = (oBN: boolean, tR: boolean) => {
    if (oBN) {
      setSortedQuestion([...questions].sort((a, b) => (tR ? b.order - a.order : a.order - b.order)));
    } else {
      setSortedQuestion(
        [...questions].sort((a, b) =>
          tR
            ? parseFloat(calcPercentage(b.order)) - parseFloat(calcPercentage(a.order))
            : parseFloat(calcPercentage(a.order)) - parseFloat(calcPercentage(b.order))
        )
      );
    }
    setTableRevert(tR);
  };

  useEffect(() => {
    sortQuestions(true, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions]);

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ borderRadius: '8px', borderBottomRightRadius: 0, borderBottomLeftRadius: 0, mr: 2 }}
      >
        <Table>
          <TableHead sx={{ borderRadius: '4px' }}>
            <TableRow>
              <StyledTableCell width="70%">
                <Stack alignItems=" center" direction="row" justifyContent="space-between">
                  Question
                  <IconButton
                    onClick={() => sortQuestions(true, !tableRevert)}
                    sx={{ color: theme.palette.custom_common.white.main }}
                  >
                    <SvgIcon>
                      <SortIcon />
                    </SvgIcon>
                  </IconButton>
                </Stack>
              </StyledTableCell>
              <StyledTableCell align="left">
                <Stack alignItems=" center" direction="row" justifyContent="space-between">
                  Drop off Rate
                  <IconButton
                    onClick={() => sortQuestions(false, !tableRevert)}
                    sx={{ color: theme.palette.custom_common.white.main }}
                  >
                    <SvgIcon>
                      <SortIcon />
                    </SvgIcon>
                  </IconButton>
                </Stack>
              </StyledTableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <TableContainer
        component={Paper}
        sx={{ maxHeight: 750, borderRadius: '8px', borderTopRightRadius: 0, borderTopLeftRadius: 0, mr: 2 }}
      >
        <Table sx={{ minWidth: 700 }}>
          <TableBody sx={{ maxHeight: 750 }}>
            {sortedQuestions.map((q) => (
              <StyledTableRow key={q.id}>
                <StyledTableCell component="th" width="70%" scope="row">
                  {q.content}
                </StyledTableCell>
                <StyledTableCell align="left">{`${calcPercentage(q.order)}%`}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.custom_primary.blue,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.custom_primary.blue
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.custom_common.white.main
}));
