import { alpha, Components, Theme } from '@mui/material';

export function createButton(theme: Theme): Pick<Components, 'MuiButton'> {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: theme.shape.borderRadius,
          fontSize: 16,
          padding: '16px 32px',
          '&:hover': {
            boxShadow: 'none'
          }
        },
        sizeSmall: {
          padding: '12px 32px 12px 24px',
          fontSize: 12
        },
        containedPrimary: {
          backgroundColor: theme.palette.custom_primary.blue,
          border: `1px solid ${theme.palette.custom_common.white.main}`,
          borderRadius: theme.shape.borderRadius,
          boxShadow: `4px 4px 20px ${alpha(theme.palette.custom_primary.blue, 0.15)}`,
          '&:hover': {
            backgroundColor: theme.palette.custom_primary.blue
          }
        },
        containedSecondary: {
          color: theme.palette.custom_primary.blue,
          backgroundColor: theme.palette.custom_primary.yellow,
          border: `1px solid ${theme.palette.custom_common.white.main}`,
          borderRadius: theme.shape.borderRadius,
          boxShadow: `4px 4px 20px ${alpha(theme.palette.custom_primary.yellow, 0.25)}`,
          '&:hover': {
            backgroundColor: theme.palette.custom_primary.yellow
          }
        },
        disabled: {
          backgroundColor: theme.palette.custom_grey.main
        }
      },
      variants: [
        {
          props: { variant: 'menu' },
          style: {
            textTransform: 'none',
            border: `none`,
            borderRadius: theme.shape.borderRadiusXs,
            color: theme.palette.custom_common.white.main,
            background: theme.palette.custom_primary.blue,
            padding: '12px 32px 12px 24px'
          }
        },
        {
          props: { variant: 'green' },
          style: {
            textTransform: 'none',
            backgroundColor: theme.palette.custom_green.main,
            border: `1px solid ${theme.palette.custom_common.white.main}`,
            borderRadius: theme.shape.borderRadius,
            boxShadow: `4px 4px 20px ${alpha(theme.palette.custom_green.main, 0.25)}`
          }
        }
      ]
    }
  };
}
