import { AdminPanelItem } from 'src/components/adminPanel/switcher/types';
import { AdminUsers } from 'src/components/adminPanel/tabs/users';
import { AdminScores } from 'src/components/adminPanel/tabs/scores';
import { AdminAssessments } from 'src/components/adminPanel/tabs/assessments';
import { EditIcon, MenuIcon, UsersIcon } from 'src/assets/icons';

export const adminPanelViews: AdminPanelItem[] = [
  { key: 'Users', path: '/adminPanel/users', component: AdminUsers, icon: UsersIcon },
  { key: 'Scores', path: '/adminPanel/scores', component: AdminScores, icon: MenuIcon },
  { key: 'Assessment Generator', path: '/adminPanel/assessments', component: AdminAssessments, icon: EditIcon }
];
