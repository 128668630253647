import React from 'react';
import { Chart as ChartJS, CategoryScale, BarElement, LinearScale, ScriptableContext } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { Box, useTheme, styled } from '@mui/material';
import { getColorByScore } from 'src/utils/getColorByScore';
import { getChartColors } from 'src/components/adminPanel/charts/constants';
import { getCategoryFullName } from 'src/utils/getCategoryFullName';

ChartJS.register(CategoryScale, LinearScale, BarElement, ChartDataLabels);

interface HorizontalBarChartProps {
  labels: (string | null)[];
  data: number[];
  shouldFormat: boolean;
  direction: 'horizontal' | 'vertical';
}

export const AdminPanelBarChart = ({ labels, data, shouldFormat, direction }: HorizontalBarChartProps) => {
  const theme = useTheme();

  const calcPercentage = (value: number) => {
    const sum = data.reduce((partialSum, el) => partialSum + el, 0);
    return parseFloat(((value / sum) * 100).toFixed(0));
  };

  const options = {
    indexAxis: direction === 'horizontal' ? ('y' as const) : ('x' as const),
    barPercentage: 1,
    scales: {
      x: {
        display: direction === 'vertical',
        max: shouldFormat ? 100 : 150,
        grid: {
          drawOnChartArea: false,
          drawBorder: false,
          display: false
        }
      },
      y: {
        display: direction === 'horizontal',
        max: shouldFormat ? 100 : Math.max(...data) + 50,
        grid: {
          drawOnChartArea: false,
          drawBorder: false,
          display: false
        },
        ticks: {
          color: theme.palette.custom_grey.shade_1,
          font: {
            weight: 'bold' as const
          }
        }
      }
    },
    layout: {
      padding: {
        right: direction === 'horizontal' ? (shouldFormat ? 45 : 25) : 0
      }
    },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: ScriptableContext<'bar'>) {
            return shouldFormat
              ? direction === 'vertical'
                ? data[tooltipItem.dataIndex]
                : `${tooltipItem.raw}%`
              : tooltipItem.raw;
          }
        }
      },
      datalabels: {
        formatter: (val: number) => {
          return shouldFormat ? val + ' %' : val;
        }
      },
      floatingLabels: false
    }
  };

  const chartData = {
    labels: labels.map((label) =>
      label === null ? 'Not selected' : label === 'undefined' ? 'Other' : getCategoryFullName(label)
    ),
    datasets: [
      {
        data: direction === 'vertical' && shouldFormat ? data.map((value) => calcPercentage(value)) : data,
        backgroundColor:
          direction === 'vertical' && shouldFormat
            ? getChartColors(theme).reverse()
            : data.map((value) => {
                if (direction === 'horizontal') {
                  return shouldFormat
                    ? theme.palette.custom_primary.orange.shade_1
                    : getColorByScore(theme, value, shouldFormat);
                } else {
                  return theme.palette.custom_primary.blue;
                }
              }),
        borderRadius: 20,
        borderSkipped: false,
        maxBarThickness: 16,
        datalabels: {
          color: (chart: any) => chart.dataset.backgroundColor[chart.dataIndex],
          anchor: 'end' as const,
          align: 'start' as const,
          offset: shouldFormat && direction === 'horizontal' ? -40 : -30,
          font: {
            weight: 'bold',
            size: 12
          }
        }
      }
    ]
  };

  return (
    <ChartBox>
      {/*@ts-ignore*/}
      <Bar options={options} data={chartData} />
    </ChartBox>
  );
};

const ChartBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '340px'
});
