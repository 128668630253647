import { Components, Theme } from '@mui/material';

export function createProgressBar(theme: Theme): Pick<Components, 'MuiLinearProgress'> {
  return {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          width: '100%',
          borderRadius: theme.shape.borderRadiusXxs,
          backgroundColor: theme.palette.custom_common.white.shade_1,

          '&.vertical': {
            width: 8,
            height: '100%',

            '.MuiLinearProgress-bar': {
              backgroundColor: theme.palette.custom_primary.blue
            }
          }
        },
        bar: {
          borderRadius: theme.shape.borderRadiusXxs
        }
      }
    }
  };
}
