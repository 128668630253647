import { Components, Theme } from '@mui/material';

export function createTableFooter(theme: Theme): Pick<Components, 'MuiTableCell'> {
  return {
    MuiTableCell: {
      styleOverrides: {
        footer: {
          left: 0,
          bottom: 0,
          zIndex: 2,
          position: 'sticky',
          background: theme.palette.custom_common.white.main,
          '.MuiTablePagination-toolbar': {
            '.MuiTablePagination-displayedRows': {
              display: 'none'
            }
          }
        }
      }
    }
  };
}
