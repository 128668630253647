export const thresholdValues = [29, 59, 74, 89, 104, 119, 150];

export const percentageValues = [19, 39, 49, 59, 69, 79, 100];

export enum SCORE_LEVEL {
  CRITICAL = 'CRITICAL',
  COMPROMISED = 'COMPROMISED',
  TRANSITIONAL = 'TRANSITIONAL',
  MODERATE = 'MODERATE',
  ABOVE_AVERAGE = 'ABOVE AVERAGE',
  EXCELLENT = 'EXCELLENT',
  EXTRAORDINARY = 'EXTRAORDINARY'
}
