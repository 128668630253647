import { Theme } from '@mui/material';
import { percentageValues, thresholdValues } from 'src/components/assessment/report/constants';

export const getColorByScore = (theme: Theme, score: number, isPercentage = false): string => {
  const valuesArray = isPercentage ? percentageValues : thresholdValues;

  if (score <= valuesArray[0]) {
    return theme.palette.custom_primary.red;
  }
  if (score <= valuesArray[1]) {
    return theme.palette.custom_primary.orange.main;
  }
  if (score <= valuesArray[2]) {
    return theme.palette.custom_primary.orange.shade_1;
  }
  if (score <= valuesArray[3]) {
    return theme.palette.custom_primary.orange.shade_2;
  }
  if (score <= valuesArray[4]) {
    return theme.palette.custom_green.shade_1;
  }
  if (score <= valuesArray[5]) {
    return theme.palette.custom_green.main;
  }
  return theme.palette.custom_primary.blue;
};
