import { AppThunkAction } from 'src/store/types';
import { getAdminPanelAssessments } from 'src/service/assessmentData.service';
import {
  setAdminPanelAssessments,
  setAdminPanelAssessmentsPageNumber
} from 'src/store/reducers/adminPanel/adminPanelReducer';
import { batch } from 'react-redux';
import { setState } from 'src/store/reducers/state/stateReducer';
import { StateEnum } from 'src/store/reducers/state/types';

export const updateAdminPanelAssessmentsAction = (offset: number, limit: number): AppThunkAction => {
  return async function thunk(dispatch, getState): Promise<void> {
    const state = getState();
    const encodedString = state.adminPanelState.adminEncodedString;
    const params = state.adminPanelState.adminPanelFilters;
    const pageNumber = state.adminPanelState.adminPanelAssessments.pageNumber;

    if (pageNumber >= offset) {
      return;
    }

    try {
      dispatch(setState(StateEnum.PENDING));
      const adminPanelScoresData = await getAdminPanelAssessments({ ...params, offset, limit }, encodedString);

      batch(() => {
        dispatch(setAdminPanelAssessmentsPageNumber(offset));
        dispatch(setAdminPanelAssessments(adminPanelScoresData));
        dispatch(setState(StateEnum.COMPLETE));
      });
    } catch (err) {
      console.log(err);
    }
  };
};
