import { AppThunkAction } from 'src/store/types';
import { getAdminPanelScoresData } from 'src/service/assessmentData.service';
import { setAdminPanelScores } from 'src/store/reducers/adminPanel/adminPanelReducer';
import { setState } from 'src/store/reducers/state/stateReducer';
import { StateEnum } from 'src/store/reducers/state/types';
import { batch } from 'react-redux';

export const updateAdminPanelScoresAction = (): AppThunkAction => {
  return async function thunk(dispatch, getState): Promise<void> {
    const state = getState();
    const encodedString = state.adminPanelState.adminEncodedString;
    const params = state.adminPanelState.adminPanelFilters;
    try {
      dispatch(setState(StateEnum.PENDING));
      const adminPanelScoresData = await getAdminPanelScoresData(params, encodedString);

      batch(() => {
        dispatch(setAdminPanelScores(adminPanelScoresData));
        dispatch(setState(StateEnum.COMPLETE));
      });
    } catch (err) {
      console.log(err);
    }
  };
};
