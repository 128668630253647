import { Stack, Box, useTheme, Typography, styled } from '@mui/material';

import { Chart as ChartJS, ArcElement, Legend, Tooltip, Title } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { getChartColors } from 'src/components/adminPanel/charts/constants';
import { useRef } from 'react';

ChartJS.register(ArcElement, Legend, Tooltip, Title);

interface PieChartProps {
  label: string;
  chartData: number[];
  chartLabels: string[];
}

export const PieChart = ({ chartData, chartLabels, label }: PieChartProps) => {
  const theme = useTheme();

  const chartRef = useRef<any>();

  const legendColorsArr = getChartColors(theme);

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'bottom' as const,
        align: 'start' as const,
        labels: {
          boxWidth: 12,
          usePointStyle: true,
          pointStyle: 'rectRounded'
        }
      },
      floatingLabels: false
    }
  };

  const data = {
    labels: chartLabels.map((label) => (label === 'null' ? 'Rather not say' : label === 'undefined' ? 'Other' : label)),
    datasets: [
      {
        data: chartData,
        backgroundColor: getChartColors(theme),
        hoverBackgroundColor: getChartColors(theme),
        borderWidth: 0
      }
    ]
  };

  return (
    <Stack width="25%" alignItems="center" sx={{ mb: 2 }}>
      <Typography variant="label" sx={{ mb: 1 }}>
        <strong>{label.split(/(?=[A-Z])/).join(' ')}</strong>
      </Typography>
      <Box sx={{ width: '100%', height: '200px' }}>
        <Pie ref={chartRef} data={data} options={options} />
      </Box>
      {chartRef.current && (
        <LegendBox>
          {chartLabels
            .map((label) => (label === 'null' ? 'Rather not say' : label === 'undefined' ? 'Other' : label))
            .map((el, i) => (
              <Stack key={el} alignItems="center" direction="row" sx={{ mr: 1 }}>
                <Box
                  sx={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '2px',
                    backgroundColor: legendColorsArr[i],
                    mr: '4px'
                  }}
                />
                <LegendLabelTypography variant="p">{el}</LegendLabelTypography>
              </Stack>
            ))}
        </LegendBox>
      )}
    </Stack>
  );
};

const LegendBox = styled(Box)({
  width: '100%',
  marginTop: 10,
  display: 'flex',
  marginLeft: '25%',
  paddingRight: '10%',
  justifyContent: 'baseline',
  flexDirection: 'row',
  flexWrap: 'wrap'
});

const LegendLabelTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom_grey.main
}));
