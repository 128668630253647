import { Stack, Typography, useTheme } from '@mui/material';
import { SmallCard } from 'src/components/adminPanel/tabs/users/card';
import { PieChart } from 'src/components/adminPanel/charts/pieChart';
import { useSelector } from 'react-redux';
import { adminPanelSelectors } from 'src/store/selectors/adminPanel.selectors';
import { useEffect } from 'react';
import { useAdmin } from 'src/hooks/useAdmin.hook';
import { UsersTable } from 'src/components/adminPanel/tabs/users/table';

export const AdminUsers = () => {
  const theme = useTheme();
  const { updateUsers } = useAdmin();
  const tabData = useSelector(adminPanelSelectors.getAdminPanelUsersData);
  const filterData = useSelector(adminPanelSelectors.getFilterData);

  useEffect(() => {
    updateUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  return (
    <Stack
      width="80%"
      height="100%"
      alignItems="baseline"
      sx={{ backgroundColor: theme.palette.custom_common.white.shade_1 }}
    >
      <Stack direction="row" width="stretch" sx={{ m: 3 }}>
        <SmallCard variant="primary" title={tabData.general.assessments} label="Completed Assessment" />
        <SmallCard variant="secondary" title={tabData.general.uniqueUsers} label="Unique Users" />
        <SmallCard variant="secondary" title={tabData.general.countries} label="Countries" />
      </Stack>
      <Stack
        alignItems="baseline"
        sx={{ p: 2, mr: 3, ml: 3, borderRadius: '8px', backgroundColor: theme.palette.custom_common.white.main }}
      >
        <Typography variant="h5" color={theme.palette.custom_primary.blue}>
          User profile
        </Typography>
        <Stack direction="row" flexWrap="wrap" sx={{ mt: 3 }}>
          {Object.keys(tabData.userProfile).map((key) => {
            return (
              <PieChart
                key={key}
                label={key}
                chartData={Object.values(tabData.userProfile[key as keyof typeof tabData.userProfile]).filter(
                  (val) => val !== 0
                )}
                chartLabels={Object.keys(tabData.userProfile[key as keyof typeof tabData.userProfile]).filter(
                  (label) => tabData.userProfile[key as keyof typeof tabData.userProfile][label] !== 0
                )}
              />
            );
          })}
        </Stack>
      </Stack>
      <Stack
        alignItems="center"
        sx={{ p: 3, width: 'stretch', backgroundColor: theme.palette.custom_common.white.shade_1 }}
      >
        <UsersTable
          data={tabData.dropOffRate.map((el) => {
            return { questionNumber: el.order, dropOffRate: parseInt(el.count) };
          })}
          questions={tabData.questions}
        />
      </Stack>
    </Stack>
  );
};
