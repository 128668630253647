import qs from 'qs';
import { AssessmentInterface, AssessmentRequestData } from 'src/types/assessment';
import { getAxiosInstance } from 'src/service/getAxiosInstance';
import { CategoryInterface } from 'src/store/reducers/categories/types';
import { AnswerParams } from 'src/types/answer';
import { AnswerInterface, ResultInterface } from 'src/store/reducers/assessment/types';
import { ContentRequestParams, ContentResponse } from 'src/types/content';
import {
  AdminPanelAssessments,
  AdminPanelFilters,
  AdminPanelScores,
  AdminPanelUsers
} from 'src/store/reducers/adminPanel/types';

export const getHelloWorld = async (): Promise<void> => {
  const response = await getAxiosInstance().get('');

  return response.data;
};

export const getAssessment = async (id: string): Promise<AssessmentInterface> => {
  const response = await getAxiosInstance().get(`/assessment/${id}`);

  return response.data;
};

export const updateAssessment = async (
  data: Partial<AssessmentRequestData> & Pick<AssessmentInterface, 'id'>
): Promise<string> => {
  const response = await getAxiosInstance().post('/assessment', data);

  return response.data;
};

export const getCategories = async (): Promise<CategoryInterface[]> => {
  const response = await getAxiosInstance().get('/categories');

  return response.data;
};

export const createAssessment = async (data: AssessmentRequestData): Promise<string> => {
  const response = await getAxiosInstance().post('/assessment', data);

  return response.data;
};

export const addAnswer = async (
  assessmentId: string,
  questionId: string,
  data: AnswerParams
): Promise<AnswerInterface> => {
  const response = await getAxiosInstance().put(`assessment/${assessmentId}/answer/${questionId}`, {
    assessmentId,
    questionId,
    ...data
  });

  return response.data;
};

export const getResults = async (assessmentId: string): Promise<ResultInterface> => {
  const response = await getAxiosInstance().get(`assessment/${assessmentId}/results`);

  return response.data;
};

export const getContent = async (params: ContentRequestParams[]): Promise<ContentResponse[]> => {
  const response = await getAxiosInstance().post('categories/content', params);

  return response.data;
};

// Admin panel

export const login = async (encodedString: string): Promise<void> => {
  const response = await getAxiosInstance().get('/admin', { headers: { Authorization: encodedString } });

  return response.data;
};

export const getAdminPanelUsersData = async (
  params: Partial<AdminPanelFilters>,
  encodedString: string
): Promise<AdminPanelUsers> => {
  const response = await getAxiosInstance().get('/admin/users', {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    headers: { Authorization: encodedString }
  });

  return response.data;
};

export const getAdminPanelScoresData = async (
  params: Partial<AdminPanelFilters>,
  encodedString: string
): Promise<AdminPanelScores> => {
  const response = await getAxiosInstance().get('/admin/scores', {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    headers: { Authorization: encodedString }
  });

  return response.data;
};

export const getAdminPanelAssessments = async (
  params: Partial<AdminPanelFilters>,
  encodedString: string
): Promise<AdminPanelAssessments> => {
  const response = await getAxiosInstance().get('/admin/assessments', {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    headers: { Authorization: encodedString }
  });

  return response.data;
};

export const getAdminPanelAssessmentsData = async (
  params: Partial<AdminPanelFilters>,
  encodedString: string
): Promise<any> => {
  const response = await getAxiosInstance().get('/admin/assessments-data', {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    headers: { Authorization: encodedString }
  });

  return response.data;
};

export const getAdminPanelAssessmentDataById = async (
  assessmentId: string,
  params: Partial<AdminPanelFilters>,
  encodedString: string
): Promise<any> => {
  const response = await getAxiosInstance().get(`/admin/assessments-data/${assessmentId}`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    headers: { Authorization: encodedString }
  });

  return response.data;
};
