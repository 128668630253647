import { Components } from '@mui/material';

import ObjectivityBold from '../../assets/fonts/Objectivity-Bold.woff2';

export function createBaseline(): Pick<Components, 'MuiCssBaseline'> {
  return {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
          font-family: 'Objectivity';
          font-style: bold;
          font-display: swap;
          font-weight: 600;
          src: local('Objectivity'), local('Objectivity-Bold'), url(${ObjectivityBold}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }`
    }
  };
}
