import { Components, Theme } from '@mui/material';

export function createSelect(theme: Theme): Pick<Components, 'MuiSelect'> {
  return {
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingLeft: 16
        },
        standard: {
          background: theme.palette.background.paper,
          border: `1px solid ${theme.palette.custom_grey.shade_1}`,
          borderRadius: theme.shape.borderRadiusSm,
          color: theme.palette.custom_primary.blue
        }
      }
    }
  };
}
