function pxToRem(value: number) {
  return `${value / 16}rem`;
}

function responsiveFontSizes({ sm, md, lg }: { sm: number; md: number; lg: number }) {
  return {
    '@media (min-width:300px)': {
      fontSize: pxToRem(sm)
    },
    '@media (min-width:480px)': {
      fontSize: pxToRem(sm)
    },
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm)
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md)
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg)
    }
  };
}

const FONT = 'Inter, sans-serif';

//TODO Add mobile font sizes

export const typography = {
  fontFamily: FONT,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightExtraBold: 800,
  h1: {
    fontWeight: 800,
    lineHeight: '100%',
    fontSize: pxToRem(72),
    letterSpacing: '4%',
    ...responsiveFontSizes({ sm: 72, md: 72, lg: 72 })
  },
  h3: {
    fontWeight: 800,
    lineHeight: '140%',
    fontSize: pxToRem(48),
    letterSpacing: '4%',
    ...responsiveFontSizes({ sm: 48, md: 48, lg: 48 })
  },
  h4: {
    fontWeight: 600,
    lineHeight: '140%',
    fontSize: pxToRem(32),
    letterSpacing: '1%',
    ...responsiveFontSizes({ sm: 32, md: 32, lg: 32 })
  },
  h5: {
    fontWeight: 400,
    lineHeight: '140%',
    fontSize: pxToRem(24),
    letterSpacing: 0,
    ...responsiveFontSizes({ sm: 24, md: 24, lg: 24 })
  },
  label: {
    fontWeight: 400,
    lineHeight: '150%',
    fontSize: pxToRem(16),
    letterSpacing: '2%',
    ...responsiveFontSizes({ sm: 16, md: 16, lg: 16 })
  },
  p: {
    fontWeight: 600,
    lineHeight: '160%',
    fontSize: pxToRem(12),
    letterSpacing: 0,
    ...responsiveFontSizes({ sm: 12, md: 12, lg: 12 })
  }
} as const;
