import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createInitialAdminPanelState } from 'src/store/reducers/adminPanel/createInitialAdminPanelState';
import {
  AdminPanelAssessments,
  AdminPanelFilters,
  AdminPanelScores,
  AdminPanelSliceState,
  AdminPanelUsers
} from 'src/store/reducers/adminPanel/types';

const adminPanelSlice = createSlice({
  name: 'adminPanel',
  initialState: createInitialAdminPanelState(),
  reducers: {
    setAuth(state: AdminPanelSliceState, action: PayloadAction<boolean>): void {
      state.isAuthenticated = action.payload;
    },
    setAdminEncodedString(state: AdminPanelSliceState, action: PayloadAction<string>): void {
      state.adminEncodedString = action.payload;
    },
    setAdminPanelFilters(state: AdminPanelSliceState, action: PayloadAction<Partial<AdminPanelFilters>>): void {
      if (typeof action.payload.ageMin !== 'number') {
        action.payload.ageMin = undefined;
      }
      if (typeof action.payload.ageMax !== 'number') {
        action.payload.ageMax = undefined;
      }
      state.adminPanelFilters = action.payload;
    },
    setAdminPanelUsers(state: AdminPanelSliceState, action: PayloadAction<AdminPanelUsers>): void {
      state.adminPanelUsers = action.payload;
    },
    setAdminPanelScores(state: AdminPanelSliceState, action: PayloadAction<AdminPanelScores>): void {
      state.adminPanelScores = action.payload;
    },
    setAdminPanelAssessments(
      state: AdminPanelSliceState,
      action: PayloadAction<Omit<AdminPanelAssessments, 'pageNumber'>>
    ): void {
      if (state.adminPanelAssessments.count !== action.payload.count) {
        state.adminPanelAssessments.result = action.payload.result;
        state.adminPanelAssessments.count = action.payload.count;
        return;
      }
      action.payload.result.forEach((result) => {
        if (state.adminPanelAssessments.result.some((r) => r.id === result.id)) {
          return;
        }
        state.adminPanelAssessments.result.push(result);
      });
      state.adminPanelAssessments.count = action.payload.count;
    },
    setAdminPanelAssessmentsPageNumber(state: AdminPanelSliceState, action: PayloadAction<number>): void {
      state.adminPanelAssessments.pageNumber = action.payload;
    },
    resetAssessments(state: AdminPanelSliceState): void {
      state.adminPanelAssessments.pageNumber = -1;
      state.adminPanelAssessments.count = 0;
      state.adminPanelAssessments.result = [];
    }
  }
});

const { actions, reducer: adminPanelReducer } = adminPanelSlice;

export { createInitialAdminPanelState };

export const {
  setAuth,
  setAdminPanelFilters,
  setAdminEncodedString,
  setAdminPanelUsers,
  setAdminPanelScores,
  setAdminPanelAssessments,
  setAdminPanelAssessmentsPageNumber,
  resetAssessments
} = actions;

export default adminPanelReducer;
