import React, { useEffect, useRef, useState } from 'react';

import { useTheme, Box, IconButton, SvgIcon } from '@mui/material';
import { ArrowLeft } from 'src/assets/icons';

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

export const PaginationComponent = ({ count, page, rowsPerPage, onPageChange }: TablePaginationActionsProps) => {
  const theme = useTheme();

  const [pages, setPages] = useState<number[]>([]);

  const ref = useRef<HTMLElement>();

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  useEffect(() => {
    let temp = count;
    let numberOfPages = 0;
    let pagesArr = [];
    pagesArr.push(numberOfPages);
    while (temp > rowsPerPage) {
      temp -= rowsPerPage;
      numberOfPages++;
      pagesArr.push(numberOfPages);
    }
    setPages(pagesArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  return (
    <Box
      ref={ref}
      sx={{
        flexShrink: 0,
        mr: `calc(50% - ${ref.current ? ref.current.offsetWidth / 2 : 0}px)`,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page" sx={{ mr: -1 }}>
        {theme.direction === 'rtl' ? (
          <SvgIcon viewBox="-3 -2 18 18" sx={{ fill: theme.palette.custom_primary.blue }}>
            <ArrowLeft />
          </SvgIcon>
        ) : (
          <SvgIcon viewBox="-3 -2 18 18" sx={{ fill: theme.palette.custom_primary.blue }}>
            <ArrowLeft />
          </SvgIcon>
        )}
      </IconButton>
      {pages.map((p) => (
        <Box
          key={p}
          sx={{
            padding: 0,
            width: 30,
            height: 30,
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ml: 1,
            color: p !== page ? theme.palette.custom_primary.blue : theme.palette.custom_common.white.main,
            backgroundColor: p === page ? theme.palette.custom_primary.blue : theme.palette.custom_common.white.main,
            border: `1px solid ${theme.palette.custom_primary.blue}`
          }}
        >
          {p + 1}
        </Box>
      ))}
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        sx={{ transform: 'rotate(180deg)' }}
      >
        {theme.direction === 'rtl' ? (
          <SvgIcon viewBox="-3 -2 18 18" sx={{ fill: theme.palette.custom_primary.blue }}>
            <ArrowLeft />
          </SvgIcon>
        ) : (
          <SvgIcon viewBox="-3 -2 18 18" sx={{ fill: theme.palette.custom_primary.blue }}>
            <ArrowLeft />
          </SvgIcon>
        )}
      </IconButton>
    </Box>
  );
};
