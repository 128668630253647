import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { PATHS } from 'src/routes/paths';
import { lazyLoad } from 'src/utils/lazyLoad';
import { adminPanelViews } from 'src/components/adminPanel/switcher/steps';
import React from 'react';

export const Router = () => {
  const location = useLocation();

  return (
    <Routes location={location}>
      <Route path={PATHS.root} element={<WelcomePage />} />
      <Route path={PATHS.profileForm} element={<ProfileForm />} />
      <Route path={PATHS.assessmentFinalForm} element={<AssessmentFinalForm />} />
      <Route path={PATHS.thankYouPage} element={<ThankYouPage />} />
      <Route path={PATHS.reportPage} element={<ReportPage />} />
      <Route path={PATHS.assessment} element={<Assessment />} />
      <Route path={PATHS.assessmentQuestions} element={<Assessment />} />
      <Route path={PATHS.adminPanel} element={<AdminPanel />}>
        <Route index element={<Navigate to={adminPanelViews[0].path} replace />} />
        {adminPanelViews.map((item) => {
          const Component = item.component;
          return <Route key={item.key} path={item.path} element={<Component />} />;
        })}
      </Route>
      <Route path={PATHS.page404} element={<Page404 />} />
      <Route path="*" element={<Navigate to={PATHS.page404} replace />} />
    </Routes>
  );
};

const WelcomePage = lazyLoad(
  () => import('src/components/welcomePage'),
  (module) => module.WelcomePage,
  null
);

const ThankYouPage = lazyLoad(
  () => import('src/components/assessment/thankYouPage'),
  (module) => module.ThankYouPage,
  null
);

const ReportPage = lazyLoad(
  () => import('src/components/assessment/report'),
  (module) => module.ReportPage,
  null
);

const Assessment = lazyLoad(
  () => import('src/components/assessment'),
  (module) => module.Assessment,
  null
);

const AssessmentFinalForm = lazyLoad(
  () => import('src/components/assessment/assessmentFinalForm'),
  (module) => module.AssessmentFinalForm,
  null
);

const ProfileForm = lazyLoad(
  () => import('src/components/assessment/profileForm'),
  (module) => module.ProfileForm,
  null
);

const AdminPanel = lazyLoad(
  () => import('src/components/adminPanel'),
  (module) => module.AdminPanel,
  null
);

const Page404 = lazyLoad(
  () => import('src/components/page404'),
  (module) => module.Page404,
  null
);
