import { ReactNode } from 'react';

import { createTheme, CssBaseline, StyledEngineProvider, ThemeOptions, ThemeProvider } from '@mui/material';

import { palette } from 'src/theme/palette';
import { typography } from 'src/theme/typography';
import { shapes } from 'src/theme/shapes';
import { createComponentsOverrides } from 'src/theme/overrides';

type ThemeConfigProps = {
  children: ReactNode;
};

export const ThemeConfig = ({ children }: ThemeConfigProps) => {
  const themeOptions: ThemeOptions = {
    palette: palette,
    typography: typography,
    shape: shapes
  };

  const theme = createTheme(themeOptions);
  theme.components = createComponentsOverrides(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
